<template>
    <MasterLayout :main_name="main_name" :link="link">
        <div class="sidebar_right_content">
            <div id="Mechanics" class="w3-container w3-border city">
                <h1>{{main_name}}</h1>
            </div>
        </div>
    </MasterLayout>
</template>
<script>
import MasterLayout from '../MasterLayout.vue';

export default {
    name: 'Dashbord',
    components: { MasterLayout },
    data() {
        return {
            main_name: 'User Detail',
            link: [
                {'link': 'home', 'name': 'Home'},
            ],
            token: localStorage.getItem('auth_cus_token'),
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
